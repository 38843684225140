<template>
  <div class="helping">
      <div class="city-box">
          <div class="city-Listli" v-for="(item,key,index) in cityList" :key="index">
            <div class="city-indexes" v-if="item.length">
              {{key}}
            </div>
            <div class="city-wrap" v-if="item.length">
              <ul>
                <li class="city-li" v-for="(items,indexs) in item" :key="indexs"  @click="handleSelect(items.id)"><span>{{nameMax(items.name)}}</span></li>
              </ul>
            </div>
          </div>
      </div>
       <el-backtop  :bottom="100">
    <div
      style="{
        height: 100%;
        width: 100%;
        background-color: #f2f5f6;
        box-shadow: 0 0 6px rgba(0,0,0, .12);
        text-align: center;
        line-height: 40px;
        color: #1989fa;
      }">
      UP
    </div>
  </el-backtop>
  </div>
</template>

<script>
import { cityLetterListList } from '@/api/index/index'
export default {
  name: 'cityMap',
  data() {
    return {
      cityList: [], // 城市树列表,
      select: '项目',
      cityDelayeringList: [], // 城市扁平化列表，做搜索建议使用
      inputSearch: '' // 搜索框
    }
  },
  mounted() {
    this.select = this.$route.params.select
  },
  computed: {
    nameMax() {
      return function(e) {
        return Number(4) < e.length ? e.substring(0, 4) : e;
      }
    }
  },
  created() {
    this.getCityList();
  },
  methods: {
    getCityList() { // 获取城市列表
      cityLetterListList().then(res => {
        this.cityList = res.data;
        for (let key in res.data) {
          this.cityDelayeringList = [...this.cityDelayeringList, ...res.data[key]];
        }
        // console.log('cityDelayeringList',this.cityDelayeringList);
      })
    },
    handleSelect(itemID) { // 点击搜索建议回调
      localStorage.setItem('cityLetterId', itemID);
      // this.$router.push({ path: '/' })
      // this.$router.back()
      if (this.select === '项目') {
        localStorage.setItem('approverStatus', JSON.stringify('/projectSearch'))
        this.$router.push({
          path: '/projectSearch',
          name: 'projectSearch',
          params: {
            city_id: itemID
          }
        })
      } else {
        localStorage.setItem('approverStatus', JSON.stringify('/memberShow'))
        this.$router.push({
          path: '/memberShow',
          name: 'memberShow',
          params: {
            city_id: itemID
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.helping{
  background-color: #f6f6f6;
}
.city-box{
  width: 1200px;
  margin: 0 auto;

}
.city-indexes{
  padding-left: 10px;
  color: #84888b;
}
.city-wrap{
  width: 100%;
  padding: 2px 0;
}
.city-wrap ul{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.city-wrap ul li{
  width: 150px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #333;
  background-color: #fff;
  font-size: 16px;
  margin-right: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}
.city-wrap ul li:hover{
  box-shadow: 0 1px 3px rgba(0,0,0,.2);
  color: #3697fc;
}
.city-search{
  width: 100%;
  height: 80px;
  background-color: #fff;
  margin-bottom: 30px;
}
.city-searchInput{
  width: 1200px;
  height: 80px;
  display: flex;
  align-items: center;
  margin: 0 auto;
}
.city-searchPt{
  width: 400px;
  height: 40px;
}
.city-searchPt div{
  height: 100%;
  width: 300px;
}
</style>
